export default function ServiceCard({ title, description, image, link }) {
  return (
    <a
      href={link}
      className="m-4 rounded border border-neutral-300  text-black hover:border-neutral-400 hover:text-black hover:shadow-md "
    >
      <div className=" max-w-[370px] ">
        <img
          alt={title}
          src={image}
          width="370"
          height="206"
          className="border-b border-neutral-300"
        />
        <div className="m-4 text-center">
          <h3 className="">{title}</h3>
          <p>{description}</p>
        </div>
      </div>
    </a>
  )
}
