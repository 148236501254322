import Stars from './Stars'

const d = (date) => {
  return new Date(Number(date)).toLocaleDateString('en-us', {
    timeZone: 'America/Los_Angeles',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })
}

export default function Reviews({
  reviews,
  limit,
  rating,
  keyWord = '',
  negate = false,
}) {
  return (
    <div>
      <div className="xs:columns-1 my-4 columns-1 md:columns-2 lg:columns-3">
        {reviews
          .filter(
            (item) =>
              item.rating >= rating &&
              (negate
                ? !item.feedback.includes(keyWord)
                : item.feedback.includes(keyWord))
          )
          .map((item, i) => {
            if (i > limit) return
            return (
              <div
                key={i}
                className="mb-6 max-w-[370px] break-inside-avoid-column rounded border border-neutral-300 pt-4 text-center"
              >
                <Stars rating={item.rating} />
                <div className="m-4">
                  {rating > 4 && item.feedback.length > 250
                    ? `${item.feedback.substring(0, 250)}...`
                    : item.feedback}
                </div>
                <div className="m-4 text-sm">
                  {item.name ? item.name : 'Anonymous'} - {d(item.date_created)}
                </div>
                {item.reply && (
                  <div className="border-t border-gray-300 bg-gray-100 p-4 text-sm">
                    Reply from ProLabPrints:
                    <br />
                    {item.reply}
                  </div>
                )}
              </div>
            )
          })}
      </div>
    </div>
  )
}
