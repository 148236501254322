import Link from 'next/link'
import PageHead from '../components/PageHead'
import ServiceCard from '../components/ServiceCard'
import Reviews from '../components/Reviews'
import { DynamoDB } from '@aws-sdk/client-dynamodb'
import { DynamoDBDocument } from '@aws-sdk/lib-dynamodb'
import { withSentry } from '@sentry/nextjs'

export default function index({ reviews }) {
  const meta = {
    title: 'ProLabPrints.com AKA Fromex Photo Lab, Inc.',
    description:
      'We are located in San Diego, CA, specializing in Professional Printing on Fuji Pro Paper. We serve Pro Photographers, consumers and business clients through out the US.',
  }
  return (
    <>
      <PageHead meta={meta} />
      <h1 className="text-center">
        Not <i>Just</i> Prints - <i>Pro Lab Prints</i>
      </h1>
      <p className="mx-auto max-w-[900px] text-center  text-lg">
        Cleverly hidden in a tiny strip mall in San Diego, we are a small team
        of friendly, experienced professionals doing our best to make your
        images come to life.
      </p>
      <p className="text-center">
        Please note we are ONLINE ONLY, we do not have any in store services,
        all orders must come in from our online ordering system.
      </p>
      <div className="my-12 flex flex-wrap justify-center">
        <ServiceCard
          title="Full Service Pro Luster Prints"
          description="We check the color and brightness/exposure of your files and correct them as needed"
          image="/images/cards/full-service-fuji-pro-luster-prints.jpg"
          link="/digital-printing/fs-digital-prints/"
        />
        <ServiceCard
          title="Pro Pricing Pro Luster Prints"
          description="Printed as is, make sure you have properly color corrected your files first"
          image="/images/cards/digital-prints.jpg"
          link="/digital-printing/fuji-pro-luster-pro-pricing-prints/"
        />
        <ServiceCard
          title="Units and Package Prints"
          description="Fuji Pro Luster for units and package printing for sports and schools"
          image="/images/cards/units-and-package-printing.jpg"
          link="/digital-printing/package-printing/"
        />
        <ServiceCard
          title="Collage Prints"
          description="Multi image collage templates"
          image="/images/cards/photo-collage-templates.jpg"
          link="/photo-collages/"
        />
        <ServiceCard
          title="Large Prints and Posters"
          description="Big prints - 16x20 up to 30x40. Mounting available for most sizes"
          image="/images/cards/poster-prints.jpg"
          link="/services/large-format-and-poster-printing/"
        />
        <ServiceCard
          title="Fuji Pearl Prints"
          description="High gloss paper similar to Kodak Metalic. Great for landscape and artsy images."
          image="/images/cards/fuji-pearl-paper.jpg"
          link="/digital-printing/fuji-pearl-prints/"
        />
        <ServiceCard
          title="Die Cut Wallets"
          description="Die Cut Wallets with rounded corners, perfect for seniors"
          image="/images/cards/die-cut-wallet-size-prints-on-fuji-paper.jpg"
          link="/services/wallet-size-prints/"
        />
        <ServiceCard
          title="Locket Picture Prints"
          description="Our take on solving the locket picture problem - 25+ different sizes on a 5x7."
          image="/images/cards/locket-prints.jpg"
          link="/services/locket-photo-prints/"
        />
        <ServiceCard
          title="Mounting"
          description='2mm styrene or 3/16" gator board'
          image="/images/cards/mounting-services.jpg"
          link="/services/mounting-services/"
        />
        <ServiceCard
          title="8x10 Headshot Prints"
          description="8x10 prints with your name, some with name and contact info"
          image="/images/cards/8x10-headshots.jpg"
          link="/services/8x10-headshots/"
        />
      </div>
      <h2 className="text-center">Reviews </h2>
      <p className="text-center text-lg">
        See what our customers have to say about us:
      </p>
      <Reviews
        reviews={reviews}
        limit={5}
        rating={5}
        negate={true}
        keyWord={'locket'}
      />
      <div className="text-center">
        <Link
          href="/all-reviews/"
          className="btn btn-primary mx-auto mt-8 block w-48 text-center"
        >
          Read more reviews
        </Link>
      </div>
    </>
  )
}

export async function getStaticProps() {
  const client = new DynamoDB({
    credentials: {
      accessKeyId: process.env.ACCESS_KEY,
      secretAccessKey: process.env.SECRET_KEY,
    },
    region: 'us-west-2',
  })
  const ddbDocClient = DynamoDBDocument.from(client)

  const params = {
    TableName: 'reviews',
    KeyConditionExpression: 'lab = :lab',
    ExpressionAttributeValues: {
      ':lab': 'plp',
    },
    ScanIndexForward: false,
    ReturnConsumedCapacity: 'TOTAL',
    Limit: 25,
  }
  let data
  try {
    data = await ddbDocClient.query(params)
  } catch (e) {
    console.error(e.message)
  }

  return {
    props: {
      reviews: data.Items,
    },
    revalidate: 36000,
  }
}
