export default function Stars({ rating }) {
  return (
    <>
      {[...Array(rating)].map((star, index) => (
        <span key={index} className="my-1 text-2xl text-orange-600">
          &#9733;
        </span>
      ))}
    </>
  )
}
